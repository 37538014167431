import { ButtonLink } from '@pretto/zen/atoms/buttons/ButtonLink/ButtonLink'

import { Share } from '@pretto/picto'

import { useState } from 'react'

import { SHARING_METHODES } from './SharingLinks.config'
import { getEmailMailTo } from './SharingLinks.lib'
import * as S from './SharingLinks.styles'

type MethodeProps = 'email' | 'link'

export interface SharingLinksProps {
  isMobileShareSupported: boolean
  methodes: MethodeProps[]
  title?: string
  url: string
}

export const SharingLinks = ({ isMobileShareSupported, methodes, title, url, ...props }: SharingLinksProps) => {
  const [copySuccess, setCopySuccess] = useState(false)

  const handleClick = (methode?: MethodeProps) => () => {
    if (isMobileShareSupported) {
      navigator.share({
        title,
        url,
      })
    }

    if (methode === 'email' && title) {
      window.location.href = getEmailMailTo({ title, url })
      return
    }

    setCopySuccess(true)
    window.navigator.clipboard.writeText(url)
  }

  return (
    <S.SharingLinks {...props}>
      {isMobileShareSupported && (
        <S.SharingLinksMobile>
          <ButtonLink onClick={handleClick()} picto={Share}>
            Partager
          </ButtonLink>
        </S.SharingLinksMobile>
      )}

      <S.SharingLinksDesktop>
        <S.Title>Partager :</S.Title>
        {methodes.map((methode: MethodeProps) => (
          <S.Tooltip
            key={methode}
            content={
              copySuccess
                ? SHARING_METHODES[methode].toolTipText.after || SHARING_METHODES[methode].toolTipText.before
                : SHARING_METHODES[methode].toolTipText.before
            }
          >
            <ButtonLink onClick={handleClick(methode)} picto={SHARING_METHODES[methode].picto}>
              {SHARING_METHODES[methode].label}
            </ButtonLink>
          </S.Tooltip>
        ))}
      </S.SharingLinksDesktop>
    </S.SharingLinks>
  )
}
